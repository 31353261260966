<template>
    <div>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">

        <div v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>fetching opportunity</p>
            </div>
        </div>
        <div v-if="!fetching && opportunity==null">
            <p>Opportunity {{ $route.params.opportunityId }} not found</p>
        </div>
        <div v-if="opportunity">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/><strong> Opportunity </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow class="opportunity-row">
                        <div class="d-flex w-100 justify-content-between">
                            <div>
                            <span class="indicator" v-if="opportunity.opportunityStatus == 'New'"><CBadge color="danger">New</CBadge></span>
                            <span class="indicator" v-else><CBadge color="primary">{{opportunity.opportunityStatus}}</CBadge></span>
                            <span class="indicator"><CBadge color="info">{{opportunity.opportunityType}}</CBadge></span>
                            </div>
                            <small>{{ opportunity.createdDate | formatDateAgo }}</small>
                        </div>
                        </CRow>
                        <CRow class="opportunity-row">
                            <h5 class="mb-1">{{ opportunity.enquirerName }}</h5>
                        </CRow>
                        <div v-if="opportunity.opportunityMessage">
                        <CRow class="opportunity-row">
                            {{ opportunity.opportunityMessage }}
                        </CRow>
                        </div>
                        <div v-if="opportunity.email">
                        <CRow class="opportunity-row">
                            <a-icon type="mail" /> &nbsp;{{ opportunity.email }}
                        </CRow>
                        </div>
                        <div v-if="opportunity.phone">
                        <CRow class="opportunity-row">
                            <a-icon type="phone" /> &nbsp;{{ opportunity.phone }}
                        </CRow>
                        </div>
                        <div v-if="opportunity.source">
                        <CRow class="opportunity-row">
                            <strong>Source</strong>:&nbsp; {{ opportunity.source }}
                        </CRow>
                        </div>
                        <div v-if="opportunity.sourceDescription">
                        <CRow class="opportunity-row">
                            <strong>Source Description</strong>:&nbsp; {{ opportunity.sourceDescription }}
                        </CRow>
                        </div>
                        <div v-if="opportunity.sourceUrl">
                            <CRow class="opportunity-row">
                            <strong>Source URL</strong>:&nbsp; {{ opportunity.sourceUrl }}
                            </CRow>
                        </div>
                </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <div v-if="askForContactMatch" :key="'Match-' + askForContactMatch">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/><strong> Matching Contacts </strong>
                    </CCardHeader>
                    <CCardBody>
                        <p>Are any of these existing contacts a match for this Enquiry?</p>
                            <div v-for="(contactMatch, index) in contactMatches" :key="'MatchList-' + contactMatch.contact.contactId">
                        <CListGroupItem>
                            <CRow>
                                <CCol md="2">
                            <CButton color="primary" @click="matchContact(contactMatch.contact.contactId, index)" class="mr-1">
                            <a-icon type="mail" /> Match
                            </CButton>
                                </CCol>
                                <!-- <CCol md="10"> -->
                            <!-- <div class="d-flex w-100 justify-content-between"> -->
                            <!-- <div class="d-flex w-100" > -->
                                <CCol md="4">
                                <span>
                            <div v-if="contactMatch.contact.name">
                                <strong>{{contactMatch.contact.name}}</strong>&nbsp;
                            </div>
                            <div v-if="!contactMatch.contact.name && contactMatch.contact.givenNames && contactMatch.contact.surname">
                                <strong>{{contactMatch.contact.surname}}, {{contactMatch.contact.givenNames}}</strong>&nbsp;
                            </div>
                            <div v-if="!contactMatch.contact.name && contactMatch.contact.givenNames && !contactMatch.contact.surname">
                                <strong>{{contactMatch.contact.givenNames}}</strong>&nbsp;
                            </div>
                            <div v-if="!contactMatch.contact.name && !contactMatch.contact.givenNames && contactMatch.contact.surname">
                                <strong>{{contactMatch.contact.surname}}</strong>&nbsp;
                            </div>
                                </span>
                                <span>
                                <div v-if="contactMatch.contact.name && contactMatch.contact.givenNames && contactMatch.contact.surname">
                                    ({{contactMatch.contact.surname}}, {{contactMatch.contact.givenNames}})&nbsp;
                                </div>
                                <div v-if="contactMatch.contact.name && contactMatch.contact.givenNames && !contactMatch.contact.surname">
                                    ({{contactMatch.contact.givenNames}})&nbsp;
                                </div>
                                <div v-if="contactMatch.contact.name && !contactMatch.contact.givenNames && contactMatch.contact.surname">
                                    ({{contactMatch.contact.surname}})&nbsp;
                                </div>
                                </span>
                                </CCol>
                                <CCol md="3">
                                <span>
                            <div v-if="contactMatch.contact.email">
                                <a-icon type="mail" /> {{contactMatch.contact.email}}&nbsp;
                            </div>
                                </span>
                                </CCol>
                                <CCol md="3">
                                <span>
                            <div v-if="contactMatch.contact.phone">
                                <a-icon type="phone" /> {{contactMatch.contact.phone}}
                            </div>
                                </span>
                                </CCol>
                            <!-- </div> -->
                            </CRow>
                        </CListGroupItem>
                            </div>
                        <CButton color="secondary" @click="saveContact" class="mr-1">
                            <a-icon type="mail" /> No Match
                        </CButton>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            </div>
            <CModal
            title="Match Contact"
            size="lg"
            :show.sync="matchContactModal"
            >
            <template #footer-wrapper><span/></template>
                <CRow>
                <CCol sm="6">
                    <p>Opportunity From</p>
                    <CInput
                        label="Name"
                        :value="opportunity.name"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                    <CInput
                        label="Email"
                        :value="opportunity.email"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                    <CInput
                        label="Phone"
                        :value="opportunity.phone"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                </CCol>
                <CCol sm="6">
                    <p>Existing Contact</p>
                    <CInput
                        label="Name"
                        :value="matchedContactName"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                    <CInput
                        label="Given Names"
                        :value="matchedContactGivenNames"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                    <CInput
                        label="Surname"
                        :value="matchedContactSurname"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                    <CInput
                        label="Email"
                        :value="matchedContactEmail"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                    <CInput
                        label="Phone"
                        :value="matchedContactPhone"
                        plaintext
                        horizontal
                        addLabelClasses="labelClass"
                    />
                </CCol>
                </CRow>
                    <CRow>
                        <CCol >
                            <CButton
                            color="primary"
                            v-on:click="confirmMatch"
                            class="mr-1"
                    >Confirm</CButton>
                            <CButton
                            color="secondary"
                            v-on:click="cancelContactMatch"
                            class="mr-1"
                    >Cancel</CButton>
                        </CCol>
                    </CRow>
            </CModal>
            <CModal
            title="Linking contact..."
            color="success"
            :show.sync="linkingContactModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                        <CCol >
                        <div class="center-align">
                            <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span></p>
                        </div>
                        </CCol>
                    </CRow>
            </CModal>
            <CModal
            :title="addingNewContactModalTitle"
            color="success"
            :show.sync="addingNewContactModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                        <CCol >
                        <div class="center-align">
                            <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span></p>
                        </div>
                        </CCol>
                    </CRow>
            </CModal>
            <div id="actionsDivId" v-if="showActions" :key="'Actions-' + showActions">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/><strong> Actions </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CButton v-if="mailButton.enabled" :color="mailButton.color" @click="launchEmailEditor()" class="mr-1">
                            <a-icon type="mail" /> {{mailButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="mail" /> {{mailButton.name}}
                        </CButton>

                        <CButton v-if="phoneButton.enabled" :color="phoneButton.color" @click="call()" class="mr-1">
                            <a-icon type="phone" /> {{phoneButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="phone" /> {{phoneButton.name}}
                        </CButton>

                        <CButton v-if="noteButton.enabled" :color="noteButton.color" @click="addNoteModal = true" class="mr-1">
                            <a-icon type="phone" /> {{noteButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="phone" /> {{noteButton.name}}
                        </CButton>

                        <CButton v-if="quoteButton.enabled" :color="quoteButton.color" @click="quoteSentModal = true" class="mr-1">
                            <a-icon type="file-text" /> {{quoteButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="file-text" /> {{quoteButton.name}}
                        </CButton>

                        <CButton v-if="jobButton.enabled" :color="jobButton.color" @click="jobGoAheadModal = true" class="mr-1">
                            <a-icon type="file-text" /> {{jobButton.name}}
                        </CButton>
                        <CButton v-else color="secondary" disabled class="mr-1">
                            <a-icon type="file-text" /> {{jobButton.name}}
                        </CButton>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            </div>
            <CModal
            :title="emailResponseModalTitle"
            size="lg"
            :show.sync="emailResponseModal"
            >
            <template #footer-wrapper><span/></template>
                    <div class="border-box">
                        <p><b>To:</b> {{opportunity.email}}</p>
                        <p><b>Subject:</b> {{subject}}</p>
                    </div>
                    <div v-if="haveResponseEmailAddress">
                        <div class="border-box-NOT">
                            <tiptap v-model="emailResponseText" ref="emailEditor"/>
                        </div>
                    </div>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="sendEmail" class="mr-1">Send</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                        color="secondary"
                        v-on:click="cancelEmailResponse"
                        class="mr-1"
                    >Cancel</CButton>
                        </CCol>
                    </CRow>
            </CModal>
            <CModal
            :title="phoneResponseModalTitle"
            size="lg"
            :show.sync="phoneResponseModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                    <CCol>
                    <div class="email-to">
                        <p><b>Name:</b> {{opportunity.enquirerName}}</p>
                         <p><b>Mobile:</b> {{opportunity.phone}}</p>
                    </div>
                    <CTextarea
                        v-on:input="phoneResponseInput"
                        placeholder="Enter notes..."
                        rows="6"
                    />
                    </CCol>
                    </CRow>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="saveCallNotes">Send</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                        color="secondary"
                        v-on:click="cancelMobileResponse"
                        class="mr-1"
                    >Cancel</CButton>
                        </CCol>
                    </CRow>
            </CModal>
            <div id="communicationsDivId" v-if="showCommunications" :key="'Comms-' + showCommunications">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <a-icon type="mail" />&nbsp;
                        <a-icon type="phone" />
                        <strong> Emails and Phone Calls </strong>
                    </CCardHeader>
                    <CCardBody>
                        <div id='comunications'>
                            <div v-if="fetchingCommunications" :key="'fetchingCommunications-' + fetchingCommunications">
                                <div class="center-align">
                                    <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>fetching communications</p>
                                </div>
                            </div>
                            <CListGroup >
                                <div v-for="(communication) in communications" :key="'CommsList-' + communication.communicationId">
                                    <CommunicationRow 
                                        :communicationId="communication.communicationId" 
                                        :communicationType="communication.communicationType" 
                                        :emailTo="communication.emailTo" 
                                        :emailSubject="communication.emailSubject" 
                                        :email="communication.email" 
                                        :emailBodyHtml="communication.emailBodyHtml" 
                                        :emailBodyText="communication.emailBodyText" 
                                        :message="communication.message" 
                                        :phone="communication.phone" 
                                        :createdDate="communication.createdDate" />
                                </div>
                            </CListGroup>
                        </div>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            </div>
            <div id="notesDivId" v-if="showNotes" :key="'Notes-' + showNotes">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <a-icon type="form" />
                        <strong> Notes </strong>
                    </CCardHeader>
                    <CCardBody>
                        <div id='notes'>
                            <div v-if="fetchingNotes">
                                <p>Fetching notes...</p>
                            </div>
                            <CListGroup >
                                <div v-for="(note) in notes" :key="'NotesList-' + note.noteId">
                                    <NoteRow 
                                        :noteId="note.noteId" 
                                        :noteText="note.noteText" 
                                        :noteType="note.noteType" 
                                        :createdDate="note.createdDate" />
                                </div>
                            </CListGroup>
                        </div>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            </div>
            <div id="addNoteModalDivId">
            <CModal
            title="Add Note"
            size="lg"
            :show.sync="addNoteModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                    <CCol>

                    <CTextarea
                        v-on:input="addNoteInput"
                        placeholder="Enter notes..."
                        rows="6"
                    />
                    </CCol>
                    </CRow>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="saveNote">Save</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                                color="secondary"
                                v-on:click="cancelAddNote"
                                class="mr-1"
                            >Cancel</CButton>
                        </CCol>
                    </CRow>

            </CModal>
            </div>
            <div id="addQuoteSentNoteModalDivId">
            <CModal
            title="Quote Sent"
            size="lg"
            :show.sync="quoteSentModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                    <CCol>

                    <CTextarea
                        v-on:input="addQuoteNoteInput"
                        placeholder="Enter quote notes..."
                        rows="6"
                    />
                    </CCol>
                    </CRow>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="saveQuoteSentNote">Save</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                                color="secondary"
                                v-on:click="cancelQuoteSent"
                                class="mr-1"
                            >Cancel</CButton>
                        </CCol>
                    </CRow>

            </CModal>
            </div>
            <div id="jobGoAheadModalDivId">
            <CModal
            title="Job Go Ahead"
            size="lg"
            :show.sync="jobGoAheadModal"
            >
            <template #footer-wrapper><span/></template>
                    <CRow>
                    <CCol>

                    <CTextarea
                        v-on:input="addJobDescription"
                        placeholder="Job description"
                        rows="2"
                    />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol>

                    <CTextarea
                        v-on:input="addJobGoAheadInput"
                        placeholder="Enter job go ahead notes..."
                        rows="6"
                    />
                    </CCol>
                    </CRow>
                    <CRow class="float-left">
                        <CCol >
                            <CButton color="primary" v-on:click="createJob">Go Ahead</CButton>
                        </CCol>
                        <CCol >
                            <CButton
                                color="secondary"
                                v-on:click="cancelJobGoAhead"
                                class="mr-1"
                            >Cancel</CButton>
                        </CCol>
                    </CRow>

            </CModal>
            </div>

        </div>

        <div id="errorsDivId" v-if="errors && errors.length">
            <CRow>
                <CCol>
                    <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/><strong> Errors </strong>
                    </CCardHeader>
                    <CCardBody>
                        <div v-for="(error, index) in errors" :key="'Error-' + index">
                        <CListGroupItem>
                            {{error.message}}
                        </CListGroupItem>
                        </div>
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
        </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
// import { BIconEnvelope, BIconTelephone } from 'bootstrap-vue'
import Tiptap from '@/components/TipTap.vue'
import Note from '@/components/Note.vue'
import NoteRow from '@/components/NoteRow.vue'
import CommunicationRow from '@/components/CommunicationRow.vue'
import enrichAuth from '@/auth'


export default {

name: 'Opportunity',
components: {
    Note,
    NoteRow,
    CommunicationRow,
    Tiptap
},
  data() {
    return {
        loggedInUser: null,
        mailButton: {
            name: 'Email',
            color: 'primary',
            enabled: false
        },
        phoneButton: {
            name: 'Call',
            color: 'primary',
            enabled: false
        },
        noteButton: {
            name: 'Add Note',
            color: 'primary',
            enabled: true
        },
        quoteButton: {
            name: 'Quote Sent',
            color: 'primary',
            enabled: true
        },
        jobButton: {
            name: 'Job Go Ahead',
            color: 'primary',
            enabled: true
        },
      htmlEditor: null,
      haveResponseEmailAddress: null,
      emailResponseText: null,
      opportunity: null,
      communications: null,
      notes: null,
      errors: [],
      fetching: false,
      fetchingCommunications: false,
      fetchingContacts: false,
      fetchingNotes: false,
      contactMatches: null,
      askForContactMatch: false,
      subject: 'Opportunity',
      callNotesText: null,
      noteText: null,
      quoteNoteText: null,
      goAheadNoteText: null,
      jobDescription: null,
      showNotes: false,
      showCommunications: false,
      showActions: false,
      emailResponseModal: false,
      phoneResponseModal: false,
      addNoteModal: false,
      quoteSentModal: false,
      jobGoAheadModal: false,
    //   notesExist: false,
      matchContactModal: false,
      emailResponseModalTitle: null,
      addingNewContactModalTitle: null,
      phoneResponseModalTitle: null,
      matchedContactName: null,
      matchedContactEmail: null,
      matchedContactPhone: null,
      matchedContactGivenNames: null,
      matchedContactSurname: null,
      matchedContactId: null,
      addingNewContactModal: false,
      linkingContactModal: false
    }
  },
  methods: {
    fetchOpportunity () {
        console.log('fetch Opportunity now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        console.log('opportunityId: ' + this.$route.params.opportunityId)
        console.log('opportunityId dummy: ' + 'dummy')
        const url = process.env.VUE_APP_CRM_API_ROOT + '/opportunities/' + this.$route.params.opportunityId;
        console.log('url: ' + url)
        console.log('fetch opportunity')
        this.fetching = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Opportunity, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('fetch opportunity, response: ' + response)
                        this.opportunity = response.data
                        if (typeof this.opportunity.name === 'undefined') {
                            this.emailResponseModalTitle = 'Send email'
                            this.phoneResponseModalTitle = 'Phone call notes'
                        } else {
                            this.emailResponseModalTitle = 'Send email to ' + this.opportunity.name
                            this.phoneResponseModalTitle = 'Notes for phone call to ' + this.opportunity.name
                        }
                        if (this.opportunity.contactId != null) {
                            console.log('set askForContactMatch: false')
                            // this.askForContactMatch = false
                            // this.showNotes = true
                        } else {
                            this.fetchContactMatches()
                        }
                        if (this.opportunity.opportunityStatus == 'New') {
                            this.mailButton.name = 'Respond'
                        }
                        this.setDisplayProperties()

                        this.fetching = false;
                        this.initEmailResponse()
                        this.fetchCommunications()
                        this.fetchNotes()
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('fetch opportunity, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch opportunity, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    fetchContactMatches () {
        console.log('fetch contact matches now baby!!!')
        var url = process.env.VUE_APP_CRM_API_ROOT + '/contactmatches';
        console.log('url: ' + url)
        let params = {}
        if (typeof this.opportunity.email !== 'undefined' && this.opportunity.email != null) {
            // url = this.addUrlParam(url, 'email', this.opportunity.email)
            // params.push({"email" : this.opportunity.email})
            params.email = this.opportunity.email
        }
        if (typeof this.opportunity.phone !== 'undefined' && this.opportunity.phone != null) {
            // url = this.addUrlParam(url, 'phone', this.opportunity.phone)
            // params.push({"phone" : this.opportunity.phone})
            params.phone = this.opportunity.phone
        }
        if (typeof this.opportunity.enquirerName !== 'undefined' && this.opportunity.enquirerName != null) {
            // url = this.addUrlParam(url, 'name', this.opportunity.enquirerName)
            // params.push({"name" : this.opportunity.enquirerName})
            params.enquirerName = this.opportunity.enquirerName
        }
        console.log('fetch contact matches with url: ' + url)
        console.log('fetch contact matches with params: ' + JSON.stringify(params))

        this.fetchingContacts = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Contact matches, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url, params: params})
                    .then(response => {
                        console.log('fetch Contact matches, response: ' + JSON.stringify(response.data))
                        this.contactMatches = response.data.matchedContacts
                        // this.askForContactMatch = (response.data.matchedContacts.length > 0)
                        this.setDisplayProperties()
                        this.fetchingContacts = false
                        if (!this.askForContactMatch) {
                            console.log('In fetchContactMatches, no match, call postContact()')
                            this.postContact()
                            // this.updateContactForOpportunity(this.opportunity.contactId)
                        } 
                        console.log('fetch Contact matches, response count: ' + response.data.matchedContacts.length)
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('fetch Contact matches, axios status: ' + status)
                        this.fetchingContacts = false
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch Contact matches, axios err: ' + err)
                        this.fetchingContacts = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    fetchCommunications () {
        console.log('fetch communications now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        console.log('opportunityId: ' + this.$route.params.opportunityId)
        console.log('opportunityId dummy: ' + 'dummy')
        const url = process.env.VUE_APP_CRM_API_ROOT + '/communications?opportunityId=' + this.$route.params.opportunityId;
        console.log('url: ' + url)
        console.log('fetch communications')
        this.fetchingCommunications = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Communications, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('fetch communications, response: ' + response)
                        this.communications = response.data
                        this.fetchingCommunications = false;
                        this.initEmailResponse()
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                        this.setDisplayProperties()
                    })
                    .then(status => {
                        console.log('fetch communications, axios status: ' + status)
                        this.fetchingCommunications = false;
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch communications, axios err: ' + err)
                        this.fetchingCommunications = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    fetchNotes () {
        console.log('fetch Notes now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/notes';
        console.log('opportunityId: ' + this.$route.params.opportunityId)
        let params = {
            "opportunityId": this.$route.params.opportunityId
        }
        console.log('url: ' + url)
        console.log('fetch notes')
        this.fetchingNotes = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Notes, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url, params: params})
                    .then(response => {
                        console.log('fetch notes, response: ' + JSON.stringify(response.data))
                        this.notes = response.data
                        this.fetchingNotes = false
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                        this.setDisplayProperties()
                        // this.notesExist = (response.data.length > 0)
                    })
                    .then(status => {
                        console.log('fetch notes, axios status: ' + status)
                        this.fetchingNotes = false
                    })
                    .catch(err => {
                        console.log('fetch notes, axios err: ' + err)
                        this.errors.push(err)
                        this.fetchingNotes = false
                    })
            }
        })
    },
    initEmailResponse() {
        console.log('email response')
        if (typeof this.opportunity.email === 'undefined') {
            this.haveResponseEmailAddress = false
        } else {
            this.haveResponseEmailAddress = true
        }
        this.emailResponseBaseText = '<p>Hi ' + this.opportunity.enquirerName + ',</p>'
        if (this.opportunity.opportunityType == "Web") {
            this.emailResponseBaseText = this.emailResponseBaseText + '<p>Thanks for your enquiry from my Web Page.</p>'
            this.subject = 'Enrich Interiors Web Enquiry'
        } else {
            this.emailResponseBaseText = this.emailResponseBaseText + '<p>Thanks for your enquiry.</p>'
            this.subject = 'Enrich Interiors Enquiry'
        }
        if (this.opportunity.opportunityMessage && this.opportunity.opportunityMessage !=='') {

            this.emailResponseBaseText = this.emailResponseBaseText + '<blockquote>' + this.opportunity.opportunityMessage + '</blockquote>'
            this.emailResponseBaseText = this.emailResponseBaseText + '<p></p>'
        }
        this.emailResponseText = this.emailResponseBaseText
        // this.emailResponseBaseText = '<p>Hi ' + '</p>'
        // this.htmlEditor = new Editor({
        //     content: this.emailResponseBaseText,
        //     extensions: [
        //         StarterKit,
        //     ],
        // })
        console.log('email response initialised')
    },
    sendEmail(event) {
        console.log('in sendEmail')
        this.emailResponseModal = false
        // var editorJson = this.htmlEditor.getJSON()
        // var editorJson = this.emailResponseText.getJSON()
        // console.log('htmlEditor JSON: ' + JSON.stringify(editorJson))
        //var editorObject = JSON.parse(editorJson)
        //console.log('email text: ' + editorObject.content)
        // var editorHtml = this.htmlEditor.getHTML()
        var editorHtml = this.emailResponseText
        console.log('htmlEditor html: ' + editorHtml)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/communications';
        console.log('post email url: ' + url);
        let currentUser = this.getLoggedInUser()
        console.log('sendEmail: loggedInUser fetched: ' + JSON.stringify(currentUser))
        let communication = {
            "emailTo": this.opportunity.email,
            "emailFrom": currentUser.user.fromEmail,
            "emailFromName": currentUser.user.fromEmailName,
            "emailSubject": this.subject,
            "communicationType": "Email",
            "name": this.opportunity.enquirerName,
            "emailBodyHtml": editorHtml,
            "emailBodyText": this.convertHtmlToText(editorHtml),
            "opportunityId": this.$route.params.opportunityId
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Opportunity - send email, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: communication, url: url})
                    .then(response => {
                        console.log('Post email, response: ' + JSON.stringify(response))
                        // this.opportunity = response.data
                        delete this.$http.defaults.headers.common['Authorization'];
                        this.setDisplayProperties()
                    })
                    .then(status => {
                        console.log('post email, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('post email, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    getLoggedInUser() {
        if (this.loggedInUser == null) {
            this.loggedInUser = enrichAuth.getLoggedInUser()
        }
        return this.loggedInUser
    },
    call() {
        console.log('in opportunity call')
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.open("tel:" + this.opportunity.phone);
        }
        this.phoneResponseModal = true
        // this.$refs["phone-response-modal-ref"].show();   
    },
    convertHtmlToText(html) {
        // Create a new div element
        var tempDivElement = document.createElement("div");

        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;

        // Retrieve the text property of the element 
        let text = tempDivElement.textContent || tempDivElement.innerText || ""
        console.log('CONVERTED to: ' + text)
        return text.replace('null',' ');
    },
    cancelMobileResponse() {
        this.phoneResponseModal = false
    },
    cancelEmailResponse() {
        this.emailResponseModal = false
    },
    cancelContactMatch() {
        this.matchContactModal = false
    },
    cancelAddNote() {
        this.addNoteModal = false
    },
    cancelQuoteSent() {
        this.quoteSentModal = false
    },
    cancelJobGoAhead() {
        this.jobGoAheadModal = false
    },
    saveCallNotes() {
        console.log('in saveCallNotes, callNotesText: ' + this.callNotesText)
        this.phoneResponseModal = false
        const url = process.env.VUE_APP_CRM_API_ROOT + '/communications';
        console.log('post email url: ' + url);
        let communication = {
            "phone": this.opportunity.phone,
            "name": this.opportunity.enquirerName,
            "communicationType": "Phone",
            "message": this.callNotesText,
            "opportunityId": '' + this.$route.params.opportunityId
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Opportunity - phone notes, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: communication, url: url})
                    .then(response => {
                        console.log('Post phone notes, response: ' + JSON.stringify(response))
                        this.callNotesText = null
                        this.fetchCommunications()
                        // this.opportunity = response.data
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('post phone notes, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('post phone notes, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)

                    })
            }
        })
    },
    saveContact() {
        console.log('in saveContact')
        this.postContact()
        // this.updateContactForOpportunity(this.opportunity.contactId)
    },
    postContact() {
        console.log('in postContact')
        this.addingNewContactModalTitle = 'Adding ' + this.opportunity.enquirerName + ' to Contacts'
        this.addingNewContactModal = true
        const url = process.env.VUE_APP_CRM_API_ROOT + '/contacts';
        console.log('post contact url: ' + url);
        let contact = {
            "name": this.opportunity.enquirerName,
            "phone": this.opportunity.phone,
            "email": this.opportunity.email
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('postContact, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: contact, url: url})
                    .then(response => {
                        console.log('postContact, response: ' + JSON.stringify(response.data))
                        let contactResponse = response.data
                        console.log('postContact, set opportunity.contactId to: ' + contactResponse.contactId)
                        this.opportunity.contactId = contactResponse.contactId
                        this.updateContactForOpportunity(contactResponse.contactId)
                        this.setDisplayProperties()
                        // this.askForContactMatch = false
                        // this.showNotes = true
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                        this.addingNewContactModal = false
                    })
                    
                    .then(status => {
                        console.log('postContact, axios status: ' + status)
                        this.status = status;
                        this.addingNewContactModal = false
                    })
                    .catch(err => {
                        console.log('postContact, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)
                        this.addingNewContactModal = false
                    })
            }
        })
    },
    matchContact(contactId, index) {
        console.log('in matchContact for contactId: ' + contactId)
        this.matchedContactId = contactId
        console.log('contactMatches: ' + JSON.stringify(this.contactMatches))
        console.log('matched contact: ' + JSON.stringify(this.contactMatches[index]))
        console.log('matchedName: ' + this.contactMatches[index].contact.name)
        this.matchedContactName = this.contactMatches[index].contact.name
        this.matchedContactEmail = this.contactMatches[index].contact.email
        this.matchedContactPhone = this.contactMatches[index].contact.phone
        this.matchedContactGivenNames = this.contactMatches[index].contact.givenNames
        this.matchedContactSurname = this.contactMatches[index].contact.surname
        this.matchContactModal = true;
    },
    confirmMatch() {
        console.log('confirm match for contactId: ' + this.matchedContactId)
        this.linkingContactModal = true
        this.updateContactForOpportunity(this.matchedContactId)
        this.matchContactModal = false;
    },
    updateContactForOpportunity(contactId) {
        console.log('updateContactForOpportunity, contactId: ' + contactId)
        this.patchOpportunity(contactId, null)
        this.setDisplayProperties()
        // this.askForContactMatch = false
        // this.showNotes = true
        this.linkingContactModal = false
        this.opportunity.contactId = contactId
    },
    patchOpportunity(contactId, opportunityStatus) {
        console.log('patchOpportunity, contactId: ' + contactId + ', opportunityStatus: ' + opportunityStatus)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/opportunities/' + this.opportunity.opportunityId;
        let opportunity = {}
        if (contactId) {
            opportunity.contactId = contactId
        }
        if (opportunityStatus) {
            opportunity.opportunityStatus = opportunityStatus
        }
        console.log('patch opportunity url: ' + url + ', with body: ' + JSON.stringify(opportunity))
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                this.$http.defaults.headers.common['Authorization'] = result;
                console.log('about to go: patch opportunity url: ' + url + ', with body: ' + JSON.stringify(opportunity))
                axios({ method: 'patch', data: opportunity, url: url})
                    .then(response => {
                        console.log('patchOpportunity, response: ' + JSON.stringify(response.data))
                        // this.opportunity = response.data
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                        console.log('patchOpportunity, set opportunity.contactId to: ' + contactId)
                    })
                    .then(status => {
                        console.log('patchOpportunity, axios status: ' + status)
                        this.status = status;
                        this.linkingContactModal = false
                    })
                    .catch(err => {
                        console.log('patchOpportunity, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)
                        this.linkingContactModal = false
                    })
            }
        })
    },
    createJob() {
        console.log('start createJob')
        this.jobGoAheadModal = false
        this.postJob()
        this.postNote(this.goAheadNoteText, 'Job')
    },
    postJob() {
        console.log('start postJob')
        const url = process.env.VUE_APP_CRM_API_ROOT + '/jobs';
        console.log('post job url: ' + url);
        console.log('post job contactId: ' + this.opportunity.opportunityId);
        let job = {
            "email": this.opportunity.email,
            "phone": this.opportunity.phone,
            "customerName": this.opportunity.enquirerName,
            "jobDescription": this.ojbDescription,
            "opportunityId": this.opportunity.opportunityId,
            "contactId": this.opportunity.contactId
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('post job, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: job, url: url})
                    .then(response => {
                        console.log('post job, response: ' + JSON.stringify(response))
                        this.job = response.data
                        delete this.$http.defaults.headers.common['Authorization'];
                        this.patchOpportunity(null, 'Converted')
                        this.$router.push({ path: '/job/' + this.job.jobId})
                    })
                    .then(status => {
                        console.log('post job, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('post job, axios err: ' + err)
                        this.errors.push(err)

                    })
            }
        })
    },
    saveQuoteSentNote() {
        console.log('save quote note: ' + this.quoteNoteText)
        this.postNote(this.quoteNoteText, 'Quote')
        // this.fetchNotes()
        this.setDisplayProperties()
        this.quoteSentModal = false
    },
    saveNote() {
        console.log('save note: ' + this.noteText)
        this.postNote(this.noteText, 'General')
        // this.fetchNotes()
        this.setDisplayProperties()
        this.addNoteModal = false
    },
    postNote(noteText, noteType) {
        console.log('post note: ' + this.noteText)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/notes';
        console.log('post notes url: ' + url);
        let note = {
            "noteText": noteText,
            "noteType": noteType,
            "opportunityId": this.$route.params.opportunityId
        }
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('postNote, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'post', data: note, url: url})
                    .then(response => {
                        console.log('postNote, response: ' + JSON.stringify(response))
                        // this.opportunity = response.data
                        delete this.$http.defaults.headers.common['Authorization'];
                        this.fetchNotes()
                    })
                    .then(status => {
                        console.log('postNote, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('postNote, axios err: ' + err)
                        this.errors.push(err)

                    })
            }
        })
    },
    phoneResponseInput(event) {
        console.log('phoneResponseInput: ' + event)
        this.callNotesText = event
    },
    addNoteInput(event) {
        console.log('addNotes: ' + event)
        this.noteText = event
    },
    addQuoteNoteInput(event) {
        console.log('addQuoteNotes: ' + event)
        this.quoteNoteText = event
    },
    addJobDescription(event) {
        this.jobDescription = event
    },
    addJobGoAheadInput(event) {
        console.log('addJobGoAheadInput: ' + event)
        this.goAheadNoteText = event
    },
    hide(event) {
        console.log('hide event: ' + event)
    },
    addUrlParam(url, paramName, paramValue) {

        if (typeof url === 'undefined') {
            console.error('invalid url for addUrlParam')
            return
        }

        if (typeof paramName === 'undefined') {
            console.error('invalid paramName for addUrlParam')
            return
        }

        if (typeof paramValue === 'undefined') {
            console.error('invalid paramValue for addUrlParam')
            return
        }

        if (url.indexOf("?") > 0) {
            return encodeURI(url + '&' + paramName + '=' + paramValue) //.replace("+", "%2b")
        }

        return encodeURI(url + '?' + paramName + '=' + paramValue) //.replace("+", "%2b")
    },
    setDisplayProperties() {

        if (this.opportunity.contactId == null && this.contactMatches != null && this.contactMatches.length > 0) {
            this.askForContactMatch = true
            this.showCommunications = false
            this.showActions = false
            this.showNotes = false
        } else {
            this.askForContactMatch = false
            this.showCommunications = true
            this.showActions = true
            if (this.notes != null && this.notes.length > 0) {
                this.showNotes = true
            } else {
                this.showNotes = false
            }
        }

        if (this.opportunity.opportunityStatus == 'New') {
            this.mailButton.name = 'Respond'
            this.mailButton.color = 'danger'
            this.phoneButton.color = 'danger'
        } else {
            this.mailButton.name = 'Email'
            this.mailButton.color = 'primary'
            this.phoneButton.color = 'primary'
        }
        if (this.opportunity.email) {
            this.mailButton.enabled = true
        } else {
            this.mailButton.enabled = false
        }
        if (this.opportunity.phone) {
            this.phoneButton.enabled = true
        } else {
            this.phoneButton.enabled = false
        }
        
        // if (this.askForContactMatch) {
        //     this.showNotes = false
        // } else {
        //     if (this.notes != null && this.notes.length > 0) {
        //         this.showNotes = true
        //     } else {
        //         this.showNotes = false
        //     }
        // }
    },
    launchEmailEditor() {
        console.log('Launch emailResponseModal...')
        this.emailResponseModal = true
        this.$nextTick(() => {
            this.focusEmailEditorInput();
        });
    },
    focusEmailEditorInput() {
        console.log('focusEmailEditorInput: start')
        // console.log('focusEmailEditorInput: $refs: ' + JSON.stringify(this.$refs))
        // this.$refs.emailEditor.$el.focus();
        this.$refs.emailEditor.focusEditor();
    }

  },

  // Fetches posts when the component is created.
  mounted() {
        console.log('call fetch for Opportunity!!!')
        this.fetchOpportunity()
        console.log('Opportunity:created')
  }
//   ,
//   beforeRouteUpdate (to, from, next) {
//         console.log('call fetch!!!')
//         this.fetchOpportunity()
//         console.log('Opportunity:beforeRouteUpdate')
//         this.$refs.topLoader.done();
//         console.log(to);//not even this
//         next();
//   }
}

</script>

<style>
.indicator {
    margin-right: 10px;
    font-size: large;
}

.opportunity-row {
    padding-bottom: 5px;
}

</style>
